
























































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { ApiFacade } from '@/services/ApiFacade';
import { ActivationTokenDto } from '@/models/dto/ActivationTokenDto';
import LoginFormHeaderLogo from '@/views/auth/components/LoginFormHeaderLogo.vue';
import { createMinLenValidator, requiredValidator } from '@/services/defaultFormValidators';
import { VForm } from '@/types/VForm';
import { promiseTimer } from '@/services/promiseTimer';

interface FinishRegistrationView {
  $refs: {
    loginForm: VForm;
  };
}

@Component({
  components: { LoginFormHeaderLogo },
})
class FinishRegistrationView extends Vue {
  @Inject() apiFacade!: ApiFacade;
  isLoading: boolean = false;
  isSending: boolean = false;
  token: ActivationTokenDto | null = null;
  rules = {
    requiredValidator,
    minLenValidator: createMinLenValidator(8),
    noSpacesOnEdges: (v: string) => !/(^\s)|(\s)$/.test(v) || 'Без пробелов в начале и конце',
  };

  password: string = '';
  showPassword: boolean = false;

  error: Error | string | null = null;
  isRegistrationFinished: boolean = false;

  // @Mixins() mix

  async created() {
    this.isLoading = true;
    try {
      const tokenId = this.$route.params.tokenId;
      this.token = await this.apiFacade.fetchFinishRegistrationToken(tokenId);
    } catch (err) {
      this.token = null;
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  async submitForm(event: Event) {
    event.preventDefault();
    if (!this.token || !this.$refs.loginForm.validate()) {
      return false;
    }
    try {
      this.isSending = true;

      const result = await this.apiFacade.activateUser(this.token.token, this.password);
      if (result) {
        this.isRegistrationFinished = true;
        // window.location.href = '/';
        await promiseTimer(5000);
        this.$router.push({ name: 'login' });
      } else {
        this.error = 'Ошибка подтверждения';
      }
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.isSending = false;
    }

    return false;
  }

  get errorMessage() {
    if (!this.error) {
      return '';
    }
    if (typeof this.error === 'string') {
      return this.error;
    }
    return this.error.message || '';
  }
}

export default FinishRegistrationView;
